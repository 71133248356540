import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from 'app/redux/store';

import ModalsContainer from 'app/containers/ModalsContainer';
import UniversalNavigationComponent from 'app/components/UniversalNavigationComponent';

window.global_store = window.global_store || store;

document.addEventListener('DOMContentLoaded', () => {
  const NAVIGATION_MOUNT_NODE = document.getElementById('universal_navigation_root');

  if (NAVIGATION_MOUNT_NODE) {
    ReactDOM.render(
      <Provider store={window.global_store}>
        <ModalsContainer>
          <UniversalNavigationComponent />
        </ModalsContainer>
      </Provider>,
      NAVIGATION_MOUNT_NODE,
    );
  }
});
