import React, { useEffect } from 'react';

import TransientNavContainer from 'app/containers/TransientNavContainer';

const UniversalNavigationComponent = () => {
  const toggleMenu = () => {
    const uMenu = document.querySelector('.universal-menu');
    const uMenuBg = document.querySelector('.universal-menu-bg');

    document.body.classList.toggle('no-scroll');
    uMenu.classList.toggle('show');
    uMenuBg.classList.toggle('show');
  };

  useEffect(() => {
    const menuLink = document.querySelector('#menu_link');
    menuLink.addEventListener('click', toggleMenu);
  }, []);

  try {
    return (
      <>
        { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <div
          role="button"
          tabIndex={0}
          className="universal-menu-bg"
          onClick={toggleMenu}
        />

        <div className="universal-menu">
          <div className="row end-xs sticky">
            <a
              role="button"
              tabIndex={0}
              className="universal-close"
              onClick={toggleMenu}
            >
              <img src="/plus_frontend/assets/svg/x-close.svg" alt="close" />
            </a>
          </div>
          <TransientNavContainer isWithinRouterContext={false} />
        </div>
      </>
    );
  } catch (e) {
    console.error('error!', e);
    return <h1>error!</h1>;
  }
};

export default UniversalNavigationComponent;
